// Framework and third-party non-ui
import React, { useContext } from 'react';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';

// Context & hooks
import { AppContext } from 'contexts/AppContext';

// Component specific modules (Component-styled, etc.)
import {
  StyledAppView,
  StyledHeaderContainer,
  StyledContentMapContainer,
  StyledContentContainer,
  StyledMapContainer,
} from './AppView-styled';

// App components
import Header from 'components/Header';
import Message from 'components/Message';
import CovidFilterSubNav from 'components/CovidFilterSubNav';
import FluFilterSubNav from 'components/FluFilterSubNav';
import MonkFilterSubNav from 'components/MonkFilterSubNav';
import ListView from 'pages/ListView';
import FilterView from 'pages/FilterView';
import FacilityDetailsView from 'pages/FacilityDetailsView';
import VaccineTypeToggle from 'components/VaccineTypeToggle/VaccineTypeToggle';
import Map from 'components/Map';

// App Constants
import { isFluSeason, isMonkPoxSeason } from 'constants/map';

//import Popup from 'components/Map/Popup';

// Third-party components (buttons, icons, etc.)

// JSON

// CSS

const AppView = (props) => {
  const { mapView, filterVisible, vaccineTypeFilter } = useContext(AppContext);
  let match = useRouteMatch(
    '/locations/' + vaccineTypeFilter.VaccineType + '/'
  );
  let showFilter = useRouteMatch({
    path: ['/locations/' + vaccineTypeFilter.VaccineType + '/', '/'],
    exact: true,
  });

  const { search } = useLocation();
  const mView = new URLSearchParams(search).get('mView');

  return (
    <StyledAppView hide={!match}>
      <Message />
      <Route
        path={'/locations/' + vaccineTypeFilter.VaccineType + '/'}
        component={Header}
      />
      <StyledHeaderContainer showFilter={showFilter}>
        {(isFluSeason || isMonkPoxSeason) && <VaccineTypeToggle />}
      </StyledHeaderContainer>
      {vaccineTypeFilter.VaccineType === 'Monkeypox' && <MonkFilterSubNav />}
      {vaccineTypeFilter.VaccineType === 'Flu' && <FluFilterSubNav />}
      {vaccineTypeFilter.VaccineType === 'COVID' && <CovidFilterSubNav />}
      <StyledContentMapContainer
        aria-label="Locations View"
        filterVisible={filterVisible}
        aria-hidden={!match}
      >
        <StyledContentContainer
          mView={mView}
          filterVisible={filterVisible}
          aria-label="Vaccination locations"
        >
          <Route
            exact
            path={'/locations/' + vaccineTypeFilter.VaccineType + '/'}
            component={ListView}
          />
          <Route
            exact
            path={'/locations/' + vaccineTypeFilter.VaccineType + '/:id'}
            component={FacilityDetailsView}
          />
          {filterVisible && <FilterView />}
        </StyledContentContainer>
        <StyledMapContainer
          role="application"
          hasMapView={!!mapView}
          mView={mView}
          aria-hidden={!match}
          aria-label="Map View"
        >
          <Map />
          {/* <Popup /> */}
        </StyledMapContainer>
      </StyledContentMapContainer>
    </StyledAppView>
  );
};

AppView.propTypes = {};

AppView.defaultProps = {};

export default AppView;
