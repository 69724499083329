import {
  geolocationToPointGraphic,
  geometryToGeographic,
  updateDisplayedFacilities,
} from 'utils/arcgis';

const _updateVaccineType = (args, setter) => {
  // do something with args if needed
  setter(args);
};

const _updateAttributeFilters = (args, setter) => {
  // do something with args if needed
  setter(args);
};

const _updateSearchTerm = (args, setter) => {
  // do something with args if needed
  setter(args);
};

const _updateSpatialFilter = (args, setter) => {
  // do something with args if needed
  setter(args);
};

const _updateShowLoading = (args, setter) => {
  //do something with args if needed
  setter(args);
};

const _updateBannerTexts = (args, setter) => {
  //do something with args if needed
  setter(args);
};

const _updateFacilitiesAvail = async (args, setter) => {
  //
  const facilitiesAvail = await updateDisplayedFacilities({
    ...args,
    ignoreMap: true,
    presetClause: `AppointmentAvailability = 'Available'`,
    mergeClause: true,
    returnGeometry: false,
  });
  setter(facilitiesAvail);
};

const _updateFocalPoint = async (args, setter, searchTermSetter) => {
  let { position, graphic } = args;

  if (graphic) {
    // ensure that a point geometry is in WKID 4326 for consistency
    // (the spatial reference matters when doing proximity calculations elsewhere)
    graphic.geometry = geometryToGeographic(graphic.geometry);
  } else if (position) {
    // turn the position into a Graphic with point geometry in WKID 4326
    graphic = geolocationToPointGraphic(position);

    // Update search bar text for your location
    searchTermSetter('Near me');
  }

  setter(graphic);
};

const _updateAttributeSort = (args, setter) => {
  // do something with args if needed
  setter(args);
};

const _updateSortBy = (args, setter) => {
  // do something with args if needed
  setter(args);
};

const _updateSelectedFacility = async (
  args,
  setter,
  vaccineTypeFilter,
  { history }
) => {
  // If the route is still on locations, persist the facility id the route
  if (history.location.pathname.startsWith('/locations')) {
    history.push({
      pathname: args
        ? `/locations/${vaccineTypeFilter.VaccineType}/${args.attributes.OBJECTID}`
        : `/locations/${vaccineTypeFilter.VaccineType}`,
    });
  }

  // Set the context value
  setter(args);
};

const _updateFacilities = async (args, setter) => {
  setter(null);
  let facilities = await updateDisplayedFacilities({
    ...args,
  });
  if (facilities?.length !== 0) {
    setter(facilities);
  } else {
    setter([]);
  }
};

//Comparison Function to sort monkeypox sites by availability, orderBy isn't enough
//The order is Available -> Null/Blank/Unknown -> NotAvailable, a service with NotAvail is treated as Unknown if walkins are yes for it
// removing appointment availability functionality but it's possible that it will be reinstated - Oct 2022
// function compareAvail(a, b) {
//   if (
//     a?.attributes?.AppointmentAvailability === 'Available' &&
//     (!b?.attributes?.AppointmentAvailability ||
//       b?.attributes?.AppointmentAvailability === 'Unknown' ||
//       b?.attributes?.AppointmentAvailability === undefined ||
//       b?.attributes?.AppointmentAvailability === '' ||
//       b?.attributes?.AppointmentAvailability === 'NotAvailable')
//   )
//     return -1;
//   if (
//     (!a?.attributes?.AppointmentAvailability ||
//       a?.attributes?.AppointmentAvailability === 'Unknown' ||
//       a?.attributes?.AppointmentAvailability === undefined ||
//       a?.attributes?.AppointmentAvailability === '' ||
//       (a?.attributes?.AppointmentAvailability === 'NotAvailable' &&
//         a?.services[0]?.Intake_WalkIn === 'Yes')) &&
//     b?.attributes?.AppointmentAvailability === 'Available'
//   )
//     return 1;
//   if (
//     (!a?.attributes?.AppointmentAvailability ||
//       a?.attributes?.AppointmentAvailability === 'Unknown' ||
//       a?.attributes?.AppointmentAvailability === undefined ||
//       a?.attributes?.AppointmentAvailability === '' ||
//       (a?.attributes?.AppointmentAvailability === 'NotAvailable' &&
//         a?.services[0]?.Intake_WalkIn === 'Yes')) &&
//     b?.attributes?.AppointmentAvailability === 'NotAvailable'
//   )
//     return -1;
//   if (
//     a?.attributes?.AppointmentAvailability === 'NotAvailable' &&
//     (!b?.attributes?.AppointmentAvailability ||
//       b?.attributes?.AppointmentAvailability === 'Unknown' ||
//       b?.attributes?.AppointmentAvailability === undefined ||
//       b?.attributes?.AppointmentAvailability === '' ||
//       (b?.attributes?.AppointmentAvailability === 'NotAvailable' &&
//         b?.services[0]?.Intake_WalkIn === 'Yes') ||
//       b?.attributes?.AppointmentAvailability === 'Available')
//   )
//     return 1;
//   return 0;
// }

const _updateSortedFacilities = async (args, setter, loadState, loadSetter) => {
  if (loadState !== 0) _updateShowLoading(2, loadSetter);
  let sortedFacilities = null;
  setter(sortedFacilities);
  let queryAttributes;
  if (args.vaccineTypeFilter.VaccineType === 'COVID') {
    queryAttributes = {
      ...args,
      mergeClause: true,
      returnGeometry: false,
      orderBy: ['FacilityName', 'Address'],
    };
  } else if (args.vaccineTypeFilter.VaccineType === 'Monkeypox') {
    queryAttributes = {
      ...args,
      mergeClause: true,
      returnGeometry: false,
      orderBy: ['AppointmentAvailability', 'FacilityName', 'Address'],
    };
  } else {
    queryAttributes = {
      ...args,
      mergeClause: true,
      returnGeometry: false,
      orderBy: ['FacilityName', 'Address'],
    };
  }
  sortedFacilities = await updateDisplayedFacilities(queryAttributes);

  //If monkeypox, we need a custom sort for the appointment availability
  // removing appointment availability functionality but it's possible that it will be reinstated - Oct 2022
  // if (args.vaccineTypeFilter.VaccineType === 'Monkeypox') {
  //   sortedFacilities.sort(compareAvail);
  // }

  // Sort all facilities
  const vax4nyc = [];
  const hh = [];
  const nys = [];
  const pharm = [];
  const reg = [];
  if (
    sortedFacilities !== null &&
    args.vaccineTypeFilter.VaccineType === 'COVID'
  ) {
    sortedFacilities.forEach((facility) => {
      if (facility.attributes.Website.includes('vax4nyc')) {
        vax4nyc.push(facility);
      } else if (
        facility.attributes.FacilityName.includes('NYC Health + Hospitals') ||
        facility.attributes.Website.includes('nychealthandhospitals')
      ) {
        hh.push(facility);
      } else if (facility.attributes.FacilityName.includes('NYS')) {
        nys.push(facility);
      } else if (facility.attributes.FacilityType === 'Pharmacy') {
        pharm.push(facility);
      } else {
        reg.push(facility);
      }
    });
    setter([...vax4nyc, ...hh, ...nys, ...pharm, ...reg]);
    if (loadState === 0) _updateShowLoading(1, loadSetter);
    else _updateShowLoading(3, loadSetter);
  } else {
    setter(sortedFacilities);
    if (loadState === 0) _updateShowLoading(1, loadSetter);
    else _updateShowLoading(3, loadSetter);
  }
};

const _updateMapView = async (args, setter) => {
  // do something with args if needed
  await setter(args);
};

export {
  _updateVaccineType,
  _updateAttributeFilters,
  _updateSearchTerm,
  _updateSpatialFilter,
  _updateFocalPoint,
  _updateAttributeSort,
  _updateSortBy,
  _updateSelectedFacility,
  _updateFacilities,
  _updateSortedFacilities,
  _updateFacilitiesAvail,
  _updateMapView,
  _updateShowLoading,
  _updateBannerTexts,
};
