import React, { useEffect, useState } from 'react';
import LocationHoursSection from 'components/LocationHoursSection';

import {
  AccordionTitleContainer,
  SubnTitleContainer,
  DetailSection,
  StyledButton,
} from './LocationVaccineSection-styled';

// Third-party components (buttons, icons, etc.)
import { CalciteP, CalciteA } from 'calcite-react/Elements';
import Accordion, {
  AccordionSection,
  AccordionTitle,
  AccordionContent,
} from 'calcite-react/Accordion';

import CheckIcon from 'calcite-ui-icons-react/CheckIcon';
import ChevronDownIcon from 'calcite-ui-icons-react/ChevronDownIcon';
import ChevronUpIcon from 'calcite-ui-icons-react/ChevronUpIcon';

const checkStyle = {
  paddingInlineStart: '0.5rem',
  paddingInlineEnd: '0.5rem',
};
const chevronStyle = {
  paddingInlineStart: '0.5rem',
  paddingInlineEnd: '0.5rem',
};

const VaccineDetail = ({ service }) => {
  const renderService = (serviceType) => {
    if (serviceType === 'COVID Vaccine Moderna 12+') {
      return 'Moderna (12+)';
    } else if (serviceType === 'COVID Vaccine Moderna 0-11') {
      return 'Moderna (under 11)';
    } else if (serviceType === 'COVID Vaccine Pfizer 12+') {
      return 'Pfizer (12+)';
    } else if (serviceType === 'COVID Vaccine Pfizer-5-11') {
      return 'Pfizer 5-11';
    } else if (serviceType === 'COVID Vaccine Pfizer 0-4') {
      return 'Pfizer (under 4)';
    } else if (serviceType === 'COVID Vaccine Novavax') {
      return 'Novavax (12+)';
    } else {
      return serviceType;
    }
  };

  const [activeSectionIndexes, setActiveSecionIndexes] = useState([]);

  const onAccordionChange = (evt, index) => {
    activeSectionIndexes.includes(index)
      ? setActiveSecionIndexes([])
      : setActiveSecionIndexes([index]);
  };

  const renderChev = () => {
    return activeSectionIndexes.length === 0 ? (
      <ChevronDownIcon style={chevronStyle} size={16} />
    ) : (
      <ChevronUpIcon style={chevronStyle} size={16} />
    );
  };

  const renderInsuranceInfo = () => {
    let insuranceInfo = '';
    if (
      service.ServiceType === 'Flu Vaccine' ||
      service.ServiceType === 'Monkeypox'
    ) {
      let availInsurance = [];
      if (service.Payment_Medicaid === 'Yes') availInsurance.push('Medicaid');
      if (service.Payment_Private === 'Yes') availInsurance.push('Private');
      if (service.Payment_LowNoCost === 'Yes') availInsurance.push('No-Cost');
      insuranceInfo = availInsurance.join(', ');
    } else {
      insuranceInfo = 'All COVID-19 Vaccines are completely free';
    }

    return insuranceInfo;
  };

  const renderApptRequired = () => {
    if (service.Intake_ApptRequired === 'Yes') {
      return 'Appointment Required';
    } else if (service.Intake_ApptRequired === 'No') {
      return 'Appointment Not Required';
    } else {
      return null;
    }
  };

  return (
    <Accordion
      iconPosition=""
      onAccordionChange={onAccordionChange}
      activeSectionIndexes={activeSectionIndexes}
    >
      <AccordionSection>
        <AccordionTitle>
          <AccordionTitleContainer>
            <SubnTitleContainer>
              <CheckIcon style={checkStyle} size={16} />
              {renderService(service?.ServiceType)}
              {renderChev()}
            </SubnTitleContainer>
          </AccordionTitleContainer>
        </AccordionTitle>
        <AccordionContent>
          {!!service?.AdditionalInfo && service.ServiceType !== 'Monkeypox' ? (
            <DetailSection>
              <CalciteP>{service.AdditionalInfo}</CalciteP>
            </DetailSection>
          ) : null}
          <LocationHoursSection service={service} />
          <DetailSection>
            <CalciteP>{renderApptRequired()}</CalciteP>
          </DetailSection>
          <DetailSection>
            {service.ServiceType === 'Flu Vaccine' ? (
              <div>
                <CalciteP>
                  <b>Insurance and Payment Types Accepted:</b>
                </CalciteP>
                <CalciteP>{renderInsuranceInfo()}</CalciteP>
              </div>
            ) : null}
          </DetailSection>
        </AccordionContent>
      </AccordionSection>
    </Accordion>
  );
};

export default VaccineDetail;
