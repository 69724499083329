// Framework and third-party non-ui
import React from 'react';

// Redux operations and local helpers/utils/modules

// Component specific modules (Component-styled, etc.)
import { StyledCalciteLi } from './LocationAttribute-styled';

// App components

// Third-party components (buttons, icons, etc.)
import CheckIcon from 'calcite-ui-icons-react/CheckIcon';

// JSON
import strings from 'strings/en.json';

// CSS

const LocationAttribute = ({ field }) => {
  const renderVaccineName = (field) => {
    if (field === 'COVID Vaccine Moderna 12+') {
      return 'Moderna (12+)';
    } else if (field === 'COVID Vaccine Moderna 0-11') {
      return 'Moderna (under 11)';
    } else if (field === 'COVID Vaccine Pfizer 12+') {
      return 'Pfizer (12+)';
    } else if (field === 'COVID Vaccine Pfizer-5-11') {
      return 'Pfizer 5-11';
    } else if (field === 'COVID Vaccine Pfizer 0-4') {
      return 'Pfizer (under 4)';
    } else if (field === 'COVID Vaccine Novavax') {
      return 'Novavax (12+)';
    } else {
      return strings[field];
    }
  };

  return (
    <StyledCalciteLi key={field}>
      <CheckIcon size={16} color="#288369" />
      <span>{renderVaccineName(field)}</span>
    </StyledCalciteLi>
  );
};

export default LocationAttribute;
